import { useState, useEffect } from "react";

const CheckBox = ({
    inputSetter = null,
}) => {
    //current value variable
    const [currentValue, setCurrentValue] = useState("");

    //value change handler for input
    const handleValueChange = (e) => {
        setCurrentValue(e.target.checked);
    };

    //when current value changes passed function
    //will change specific object based value
    useEffect(() => {
        if (inputSetter) {
            inputSetter(currentValue);
        }
    }, [currentValue]);

    return (
        <div className="flex">
            <input
                type="checkbox"
                className="mr-[5px] cursor-pointer border w-8 h-8"
                onChange={(e) => {
                    handleValueChange(e);
                }}
            />
        </div>
    );
};

export default CheckBox;
