import { BsBoxes } from "react-icons/bs";
import { BiLoaderAlt } from "react-icons/bi"
import CheckBox from "./CheckBox";
import { useEffect, useState } from "react";

const UserCard = ({ admin = false, item, setCheckedPackages = null, checkedPackages = null }) => {
    const [checkedStatus, setCheckedStatus] = useState(false);
    useEffect(() => {
        if (setCheckedPackages !== null) {
            if (checkedStatus) {
                setCheckedPackages(prev => [...prev, item.id])
            } else {
                if (checkedPackages.length !== 0) {
                    const tmp = checkedPackages.filter((tracking) => { return tracking !== item.id })
                    setCheckedPackages(tmp)
                }
            }
        }
    }, [checkedStatus])

    return (
        <div className="flex items-center justify-between p-[16px] w-[100%] bg-white rounded-lg">
            <div className="flex items-center justify-between w-full">
                <div className="flex gap-[16px] items-center justify-start">
                    {admin &&
                        <CheckBox
                            inputSetter={setCheckedStatus}
                        />
                    }
                    <div className="w-[48px] h-[48px] rounded-full bg-[#efeff0] p-4">
                        <BsBoxes className="w-full h-full text-[#606060]" />
                    </div>
                    <p className="font-semibold text-[#5856d6] text-left w-[auto] text-[17px]">
                        {item.tracking_number}
                    </p>
                    {admin &&
                        <p className="font-semibold text-green-400 text-left w-[auto] text-[17px]">
                            {item.total_weight} კგ
                        </p>
                    }
                </div>
                {!admin &&
                    <div className="flex gap-[16px] items-center justify-start">
                        <p className="font-semibold text-[#5856d6] text-left w-[auto] text-[17px]">
                            მზადდება
                        </p>
                        <div className="w-6 h-6">
                            <BiLoaderAlt className="w-full h-full text-[#5856d6] animate-spin" />
                        </div>
                    </div>
                }
            </div>
            {admin &&
                <div className="flex gap-2 flex-col w-32 justify-end">
                    <p className='flex font-bold text-slate-600 text-xl'>X -
                        <span className="text-xl text-green-500"> {item.x}</span>
                    </p>
                    <p className='flex font-bold text-slate-600 text-xl'>Y -
                        <span className="text-xl text-green-500"> {item.y}</span>
                    </p>
                </div>
            }
        </div>
    )
}

export default UserCard