import { Route, Routes } from "react-router-dom";
import HomePage from "./pages/HomePage";
import InnerUserPage from "./pages/InnerUserPage";
import AdminPage from "./pages/AdminPage";
import { MainContext } from "./components/MainContext";
import WebSocketContextProvider from "./components/WebSoketContext";

function App() {
  return (
    <div className="App bg-[#f1f2f7] min-h-screen">
      <WebSocketContextProvider>
        <MainContext>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="user-page" element={<InnerUserPage />} />
            <Route path="admin-page" element={<AdminPage />} />
          </Routes>
        </MainContext>
      </WebSocketContextProvider>
    </div>
  );
}

export default App;
