import { useState, createContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { errorToast, successToast } from "./ToastAlerts";
import axios from "axios";

export const PrimaryContext = createContext(null);

export const MainContext = ({ children }) => {
  const navigate = useNavigate();
  //packs
  const [packs, setPacks] = useState([]);
  //user
  const [userData, setUserData] = useState({});
  //pack count
  const [packCount, setPackCount] = useState(0);
  //const user type
  const [userType, setUserType] = useState(0);
  //room id
  const [roomId, setRoomId] = useState("");

  //handle go in room
  const handleEnterRoom = (id) => {
    axios
      .get(`https://back.expressgroup.ge/api/trig/${userType}/${id}`)
      .then(({ data }) => {
        var customClarence = false;
        var isInYellowCorridor = false;
        var tmp = data.packs.map((item) => {
          if (item.gansabaj !== 0 && item.gansabaj !== 2) {
            customClarence = true;
          }
          if (item?.yviteli === 1) {
            isInYellowCorridor = true;
          }
          return item;
        });

        if (data.count === 0 && data.unpeydPacksCount === 0) {
          errorToast("თქვენ არ გაქვთ ჩამოსული ამანათები!");
        }
        if (isInYellowCorridor) {
          errorToast(
            "თქვენი ამანათი ყვითელ დერეფანშია და ვერ შეძლებთ გაიტანოთ ამანათი სტატუსის შეცვლამდე!"
          );
        } else if (data.unpeydPacksCount !== 0) {
          errorToast("პირველ რიგში გთხოვთ დაფაროთ ტრანსპორტირების საფასური!");
        } else if (customClarence) {
          errorToast(
            "თქვენი ამანათი განსაბაჟებელია და გატანას შეძლებთ დეკლარაციის დაბეჭდვის შემდეგ!"
          );
        } else {
          setPacks(data.packs);
          setPackCount(data.count);
          setUserData(data.user);
          navigate("/user-page");
        }
        setRoomId("");
      })
      .catch((err) => {
        errorToast(
          "მითითებული შტრიხ კოდი ან პირადობის ნომერი არ მოიძებნა. გთხოვთ გადაამოწოთ!"
        );
      });
  };

  //handle give packs
  const handleGivePack = async (packs, setPacksFunc) => {
    const packIDs = packs.map((item) => {
      return item.id;
    });

    axios
      .post(
        `https://back.expressgroup.ge/api/Pack/gatana/${userType}/${"One Peace Is Real!"}`,
        { packIds: packIDs }
      )
      .then((res) => {
        setPacksFunc([]);
        successToast("ამანათები წარმათებით გაიტანეს!");
      })
      .catch(() => {
        errorToast("დაფიქსირდა შეცდომა!");
      });
  };

  //handle give packs
  const handleGiveCheckedPacks = async (packs, setPacksFunc) => {
    axios
      .post(
        `https://back.expressgroup.ge/api/Pack/gatana/${userType}/${"One Peace Is Real!"}`,
        { packIds: packs }
      )
      .then((res) => {
        setPacksFunc([]);
        successToast("ამანათები წარმათებით გაიტანეს!");
      })
      .catch(() => {
        errorToast("დაფიქსირდა შეცდომა!");
      });
  };

  //fill user type when component mounts
  useEffect(() => {
    const type = parseInt(localStorage.getItem("USER"));
    if (type === 1 || type === 2 || type === 3) {
      setUserType(type);
    }

    const typeString = localStorage.getItem("USER_STRING");
    if (typeString === "admin") {
      navigate("/admin-page");
    } else if (typeString === "user") {
      navigate("/");
    }
  }, []);

  return (
    <PrimaryContext.Provider
      value={{
        handleEnterRoom,
        handleGivePack,
        packs,
        packCount,
        setUserType,
        userType,
        roomId,
        setRoomId,
        userData,
        handleGiveCheckedPacks,
        setUserData,
      }}
    >
      {children}
    </PrimaryContext.Provider>
  );
};
